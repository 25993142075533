






























































































import { IterableListItem } from '@/components/Triggers/types'
import { FrontendTargetData } from '@/components/Post/CheckChannelsList/types'

import { atSignedLogin } from 'piramis-base-components/src/shared/utils/tgLoginHelpers'

import { Vue, Component, Prop, Emit } from 'vue-property-decorator'

@Component({
  data() {
    return {
      atSignedLogin
    }
  }
})
export default class CheckChannelsListItem extends Vue {
  @Prop() target!: IterableListItem<FrontendTargetData>

  @Prop({ type: Boolean, required: false, default: true }) hasActions !: boolean

  @Emit()
  remove() {
    return this.target.guid
  }

  @Emit()
  edit() {
    return this.target
  }

  @Emit()
  retest() {
    return this.target
  }
}

